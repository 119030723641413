<template>
    <div>
      <vx-card title="รูปแบบการโชว์ธนาคาร">
        <vs-table stripe :data="getData">

          <template slot="thead">
            <vs-th>ID</vs-th>
            <vs-th>NAME</vs-th>
            <vs-th>ลูกค้า SCB</vs-th>
            <vs-th>ลูกค้า KBANK</vs-th>
            <vs-th>ลูกค้า OTHER (อื่นๆ)</vs-th>
            <vs-th>ลูกค้า VIP (Speacial)</vs-th>
            <!-- <vs-th>Username</vs-th>
            <vs-th>Password</vs-th> -->
            <vs-th></vs-th>
            <vs-th>กำลังใช้งาน</vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="tr.id">
                {{tr.id}}
              </vs-td>
              <vs-td :data="tr.name">
                {{tr.name}}
              </vs-td>
              <vs-td  :data="tr.template">
                <p :key="index" v-for="(step, index) in tr.template.scb" > {{(index+1) + '.' +step }}</p>
              </vs-td>
              <vs-td :data="tr.template">
                <p :key="index" v-for="(step, index) in tr.template.kbank" > {{(index+1) + '.' +step }}</p>
              </vs-td>
              <vs-td :data="tr.template">
                <p :key="index" v-for="(step, index) in tr.template.other" > {{(index+1) + '.' +step }}</p>
              </vs-td>
              <vs-td :data="tr.template">
                <p :key="index" v-for="(step, index) in tr.template.special" > {{(index+1) + '.' +step }}</p>
              </vs-td>
              <!-- <vs-td :data="tr.bank_username">
                {{tr.bank_username}}

              </vs-td>
              <vs-td :data="tr.bank_password">
                {{tr.bank_password}}
              </vs-td> -->
              <vs-td >

                <div class="flex">
                  <vs-button :disabled="!$store.state.AppActiveUser.permissions.settingBankShow.action || !tr.is_editable" type="border" size="small" icon-pack="feather" icon="icon-edit" color="warning" class="mr-2"

                  @click="$router.push('/SettingBank/EditCustomBank/'+tr.id)">แก้ไขรูปแบบโชว์ธนาคาร
                </vs-button>
   <!-- <vs-button type="border" size="small" icon-pack="feather" icon="icon-trash" class="mr-2"  @click="popup_delbank(tr.bank_id,tr.bank_number,tr.bank_fullname)">ลบบัญชีฝาก</vs-button> -->
                </div>
              </vs-td>
              <vs-td :data="tr.is_active">
                <vs-switch  v-model="tr.is_active" :disabled="$store.state.AppActiveUser.permissions.settingBankShow.action ? false : true"  @click="update_isactive(tr.id,tr.is_active,tr.name)">
                  <span slot="on">On</span>
                  <span slot="off">Off</span>
                </vs-switch>
              </vs-td>
            </vs-tr>
          </template>

        </vs-table>
      </vx-card>
    </div>
  </template>

<script>
import axios from '../../../src/axios'

export default {
  data () {
    return {
      getData: [],
      bank: 'bank',
      bankid:'',
      delete_id: '',
      delete_bankno:'',
      admin_user: this.$store.state.AppActiveUser,
      admin_pass:'',
      popup_del_bank: false
    }
  },
  async mounted () {
    await axios
      .get('bankshow/list')
      .then(response => (this.getData = response.data))
  },
  methods: {
    async update_isactive (id, is_active, name) {
      if (is_active === 1 || is_active === true) {
        is_active = false
      } else {
        is_active = true
      }
      await axios
        .post('bankshow/updateisactive', {
          id,
          is_active
        })
        .then(response => (this.onoff_status = response.data))
      if (this.onoff_status.status === true) {
        this.$vs.notify({
          time: 3000,
          color: is_active === true ? 'success' : 'warning',
          position: 'top-right',
          icon: is_active === true ? 'check_box' : 'error',
          title: is_active === true ? 'เปิดใช้งานรูปแบบการโชว์ธนาคารสำเร็จ' : 'ปิดใช้งานรูปแบบการโชว์ธนาคารสำเร็จ',
          text: is_active === true ? `รูปแบบ : ${name}` : `รูปแบบ : ${name}`
        })
      } else {
        this.$vs.notify({
          time: 8000,
          color: 'danger',
          position: 'top-right',
          icon: 'error',
          title: is_active === true ? 'เปิดใช้งานรูปแบบการโชว์ธนาคารไม่สำเร็จ!!' : 'ปิดใช้งานรูปแบบการโชว์ธนาคารไม่สำเร็จ!!',
          text: 'กรุณาติดต่อทีมงานโปรแกรมเมอร์'
        })
      }
    }
  }
}

</script>
